<template>
	<div id="signList">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			 <a-input-search v-model:value="key" placeholder="用户昵称" style="width: 400px;" enter-button @search="getSignList(1,info.limit)" />
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table row-key="id" :pagination="false" :data-source="info.list" :columns='[
				{title:"ID",dataIndex:"id"},
				{title:"用户昵称",dataIndex:"nickname"},
				{title:"签到时间",dataIndex:"sign_time",sorter: (a, b) => a.sign_time - b.sign_time},
				{title:"获得积分",dataIndex:"score",sorter: (a, b) => a.score - b.score},
				{title:"连续签到天数",dataIndex:"continue_day",sorter: (a, b) => a.continue_day - b.continue_day},
			]' >
			</a-table>
			<div class="pager">
				<a-pagination show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getSignList(info.page,e)}"
					@change="(e)=>{getSignList(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import userModel from '@/api/user.js'
import { reactive, toRefs } from 'vue';
export default{
	setup(){
		const _d = reactive({
			key:'',
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
		})
		getSignList(1,_d.info.limit)
		function getSignList(page,limit){
			userModel.getSignRecord(page,limit,{key:_d.key},res=>_d.info = {limit,...res})
		}
		return{
			...toRefs(_d),
			getSignList
		}
	},
}
</script>
<style>
</style>
